.com-contact {
  display         : flex;
  gap             : 30px;
  align-content   : space-between;
  justify-content : space-between;
  line-height     : 135%;

  &.contact {
	margin : 50px 0;
  }

  @media screen and (max-width : 600px) {
	flex-wrap : wrap;
	margin    : 30px 0;
  }

  .com-contact-info {
	h1 {
	  line-height   : 125%;
	  padding       : 30px;
	  background    : #fff;
	  border-radius : 50px;

	  @media screen and (max-width : 600px) {
		padding       : 15px 20px;
		border-radius : 25px;
	  }
	}
  }

  .com-contact__address {
	div {
	  margin-bottom : 5px;

	  a {
		color : #0095d8;
	  }
	}
  }

  .com-contact-form {
	background    : #FFF;
	border-radius : 30px;
	padding       : 30px;

	legend {
	  display : inline-block;
	}

	fieldset {
	  padding : 0;
	  border  : 0;
	}

	h3 {
	  line-height   : 125%;
	  margin-bottom : 6px;
	}

	input[type=email],
	input[type=text],
	textarea {
	  background    : #f4f4f4;
	  width         : 100%;
	  padding       : 8px 12px;
	  border-radius : 5px;
	}

	.btn {
	  background-color : #0095d8;
	  color            : #FFF;
	  padding          : 8px 12px;
	  border-radius    : 5px;
	  transition       : box-shadow .3s ease;
	  will-change      : box-shadow;

	  &:hover {
		transition : .3s;
		box-shadow : $blue 0 0 15px;
	  }
	}

	.control-group {
	  margin-bottom : 15px;

	  &.field-spacer {
		display       : inline-block;
		margin-bottom : 0;
	  }
	}
  }
}