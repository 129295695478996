// страница с карточкой товара

// карточка
.product_cards {
  margin-bottom : 50px;
}

.product_card {
  display     : flex;
  align-items : center;
  gap         : 20px;
}

// image
.hidden {
  display : none;
}

.sliderImgT {
  position : relative;
}

.sliderImgT .slick-arrow {
  position            : absolute;
  top                 : 50%;
  transform           : translateY(-50%);
  background-color    : #fff;
  background-repeat   : no-repeat;
  background-position : center;
  width               : 30px;
  height              : 30px;
  border-radius       : 50%;
  border              : none;
  font-size           : 0;
  z-index             : 2;
  box-shadow          : 0 4px 10px rgba(115, 121, 140, 0.15);
  background-image    : url("../images/card-arrow.svg");
  transition          : .3s;

  &:hover {
	transition       : .3s;
	background-color : $blue;
	background-image : url("../images/card-arrow-active.svg");
  }
}

.sliderImgT .slick-disabled {
  transition : .3s;
  opacity    : .6;

  &:hover {
	cursor           : default;
	background-color : #fff;
	background-image : url("../images/card-arrow.svg");
  }
}

.sliderImgT .slick-prev {
  transition : .3s;
  transform  : rotate(180deg);
  left       : 10px;
}

.sliderImgT .slick-next {
  transition : .3s;
  right      : 10px;
}

.product_card {
  padding               : 30px 0 0;
  display               : grid !important;
  grid-template-columns : minmax(0, 1fr) minmax(0, 1fr);
  align-items           : start !important;
}

.slideE .imgW {
  height          : auto;
  display         : flex;
  align-items     : center;
  justify-content : center;
  border-radius   : 32px;
}

.slideE img {
  max-width     : 760px;
  max-height    : 450px;
  width         : 100%;
  object-fit    : cover;
  border-radius : 32px;
}

.sliderESmall {
  margin-right    : 14px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  max-height      : 80px;
  width           : 120px !important;
  overflow        : hidden;
  border          : 2px solid transparent;
}

.sliderESmall:last-child {
  margin : 0;
}

.sliderESmall img {
  width         : 100%;
  height        : 100%;
  max-height    : 78px;
  object-fit    : cover;
  border-radius : 14px;
}

.sliderESmall.slick-current {
  border-radius : 16px;
  border        : 2px $blue solid;
}

.sliderImgB {
  overflow   : hidden;
  max-height : 80px;
  margin-top : 20px;
}

// info
.product_card_info {
}

.product_card_text {
  h1 {
	margin-bottom : 9px;
  }

  & > h3 {
	font-family   : $titleFonts;
	color         : $blue;
	font-size     : 52px;
	font-weight   : 600;
	margin-bottom : 30px;
  }

  & > p {
	line-height : 22.4px;
	color       : #171717;
  }

}

.product_card_price {
  color       : $blue;
  font-size   : 32px;
  font-weight : 500;
  margin      : 30px 0 40px;
}

.product_card_buy_block {
  display     : flex;
  align-items : center;
  gap         : 22px;
  text-align  : center;
}

.product_card_buy {
  width          : 100%;
  text-transform : uppercase;
  height         : 60px;
  padding        : 19px 0;
  color          : $BGcolor;
  border-radius  : 30px;
  transition     : .3s;

  &:hover {
	transition : .3s;
	box-shadow : 0 0 30px rgba(0, 0, 0, .12);
  }
}

.product_card_buy.blue {
  background-color : $blue;
}

.product_card_buy.orange {
  background-color : #F58335;
}


//	инфа продукта

.product_description {
  max-width : 1260px;
}

.product_description_tabs {
  height           : 43px;
  display          : flex;
  align-items      : center;
  background-color : $BGcolor;
  border-radius    : 26px;
  width            : 100%;

}

.product_description_tabs_item {
  font-size   : 16px;
  font-weight : 500;
  color       : $gray;
  margin      : 0 39px;
  cursor      : pointer;
  transition  : .3s;

  &:hover {
	color      : $black;
	transition : .3s;
  }

  @media (max-width : 1200px) {
	margin : 0 29px;
  }
  @media (max-width : 1024px) {
	margin : 0 15px;
  }
}

.product_description_tabs_item.active {
  display          : flex;
  align-items      : center;
  height           : 100%;
  transition       : .3s;
  color            : $BGcolor !important;
  background-color : $blue;
  border-radius    : 26px;
  padding          : 0 51px;
  margin           : 0;
  @media (max-width : 1200px) {
	padding : 0 41px;
  }
  @media (max-width : 1024px) {
	padding : 0 30px;
  }
}

.product_description_items {

}

.product_description_items_item {
  display : none;
}

.product_description_items_item.active {
  display : block;
}

.product_description_content {
  margin : 10px 0;

  & > h3 {
	margin-bottom : 20px;
	font-weight   : 600;
	font-size     : 20px;
  }
}

.product_description_content_block {
  color          : #171717;
  display        : flex;
  flex-direction : column;
  gap            : 12px;

  & > ul {
	display        : flex;
	flex-direction : column;
	gap            : 12px;

	& > li {
	  padding-left : 18px;
	  position     : relative;

	  &::before {
		content : "";
	  }

	  &::after {
		content          : "";
		width            : 6px;
		height           : 6px;
		border-radius    : 3px;
		background-color : $blue;
		position         : absolute;
		left             : 2px;
		top              : 6px;
	  }
	}
  }

  & > p {
	line-height : 22.4px;
  }
}
