.page298 {
  .com-content-article {
	padding       : 0;
	border-radius : 0;
	background    : transparent;
  }

  h1 {
	padding       : 30px;
	background    : #FFF;
	border-radius : 50px;
	color         : #0095d8;
  }

  h2 {
	font-size : 2.5em;
  }

  h3 {
	font-size  : 2em;
	margin-top : 2rem;
  }

  .o-kompanii-intro {
	padding : 0 30px;
	@media (max-width : 420px) {
	  padding : 0 15px;
	}
  }
}

#mod-custom153 {
  ul {
	display       : flex;
	gap           : 30px;
	margin-bottom : 58px;
	@media (max-width : 800px) {
	  flex-wrap     : wrap;
	  margin-bottom : 29px;
	}

	li {
	  margin        : 0;
	  padding       : 30px;
	  background    : #fff;
	  border-radius : 50px;
	  width         : calc(33% - 30px);

	  @media (max-width : 800px) {
		width : calc(50% - 30px);
	  }
	  @media (max-width : 420px) {
		width : 100%;
	  }

	  div {
		margin-top  : 1em;
		color       : #7B7B7B;
		line-height : 22px;
		font-size   : 16px;
	  }

	  &:before {
		display : none;
	  }
	}
  }

  h2, h3 {
	padding : 0 15px;
  }

  h3 {
	margin-bottom : 12px;
  }
}

#mod-custom154 {
  margin        : 59px 0;
  padding       : 30px;
  background    : #fff;
  border-radius : 50px;
  @media (max-width : 800px) {
	margin : 29px 0;
  }

  h2 {
	margin-top : 0;
  }
}

#mod-custom156 {
  .item {
	margin        : 59px 0;
	padding       : 30px;
	background    : #fff;
	border-radius : 50px;
	@media (max-width : 800px) {
	  margin-bottom : 29px
	}

	h2 {
	  margin-top : 0;
	}
  }
}