// блок Почему выбирают нас?   на главной странице

.advantages {
  margin : 100px auto;
  @media (max-width : 425px) {
	margin : 50px auto;
  }
}

.advantages_inner {
  text-align : center;
}

.advantages_inner .title {
  @media (max-width : 700px) {
	margin : 0 auto;
  }
}

.advantages_block {
  margin-top            : 50px;
  text-align            : left;
  display               : grid;
  grid-template-columns : repeat(4, 1fr);
  gap                   : 20px;
  @media (max-width : 1300px) {
	grid-template-columns : repeat(2, 1fr);
	grid-template-rows    : repeat(2, 1fr);
  }
  @media (max-width : 700px) {
	grid-template-columns : repeat(1, 1fr);
  }
  @media (max-width : 425px) {
	margin-top : 20px;
  }
}

.advantages_block_items {
  position         : relative;
  height           : 243px;
  border-radius    : 24px;
  background-color : $BGcolor;
  padding          : 30px 20px 0;
  display          : flex;
  flex-direction   : column;
  justify-content  : start;
  @media (max-width : 700px) {
	padding         : 30px 20px;
	justify-content : space-between;
  }
  @media (max-width : 425px) {
	padding         : 30px 20px 0;
	justify-content : start;
  }
}

.advantages_block_items_upper {
  display       : flex;
  align-items   : center;
  margin-bottom : 15px;
}

.advantages_block_items_upper_icon {
  margin-right     : 20px;
  border-radius    : 8px;
  background-color : #f9f9f9;
  width            : 50px;
  height           : 50px;
  position         : relative;

  &::after {
	position  : absolute;
	content   : "";
	top       : 50%;
	left      : 50%;
	transform : translate(-50%, -50%);
	width     : 24px;
	height    : 24px;
  }
}

//svg icon

.advantages_doc::after {
  background : url("../images/doc.svg") no-repeat;
}

.advantages_window::after {
  background : url("../images/window.svg") no-repeat;
}

.advantages_doc_checkmark::after {
  background : url("../images/doc-checkmark.svg") no-repeat;
}

//


.advantages_block_items_upper_title {
  max-width   : 190px;
  font-weight : 600;
  font-size   : 24px;
}

.advantages_block_items_upper_title_text {
  color : $gray;
}

.advantages_block_circle {
  display          : flex;
  justify-content  : center;
  align-items      : center;
  position         : absolute;
  right            : -30px;
  top              : 50%;
  transform        : translateY(-50%);
  z-index          : 1;
  padding          : 10px;
  background-color : #F4F4F4;
  border-radius    : 50%;
  @media (max-width : 700px) {
	top       : 233px;
	right     : 50%;
	transform : translateX(50%);
  }
}

#circle-none {
  @media (max-width : 1300px) {
	display : none;
  }
  @media (max-width : 700px) {
	display : block;
  }
}


.advantages_block_circle_color {
  position         : relative;
  display          : flex;
  justify-content  : center;
  align-items      : center;
  width            : 20px;
  height           : 20px;
  background-color : $blue;
  border-radius    : 50%;

  &::after {
	content    : "";
	position   : absolute;
	width      : 8px;
	height     : 8px;
	background : url("../images/checkmark.svg") no-repeat;
	top        : 50%;
	left       : 50%;
	transform  : translate(-50%, -50%);
  }
}

.b__advantages {
  .b__advantages-title {
	font-family   : "Oswald", sans-serif;
	font-size     : 76px;
	font-weight   : 500;
	margin-bottom : .65em;

	.title_color {
	  width          : 180px;
	  height         : 52px;
	  border-radius  : 26px;
	  display        : inline-block;
	  vertical-align : middle;
	  @media (max-width : 420px) {
		width          : 90px;
		height         : 26px;
	  }
	}

	@media (max-width : 800px) {
	  font-size : 42px;
	}
	@media (max-width : 420px) {
	}
  }

  .b__advantages-items {
	margin-top            : 30px;
	text-align            : left;
	display               : grid;
	grid-template-columns : repeat(3, 1fr);
	gap                   : 20px;
	@media (max-width : 800px) {
	  grid-template-columns : repeat(2, 1fr);
	}
	@media (max-width : 420px) {
	  grid-template-columns : repeat(1, 1fr);
	}

	.b__advantages-item {
	  position         : relative;
	  height           : 243px;
	  border-radius    : 24px;
	  background-color : #fff;
	  padding          : 30px 20px 0;
	  display          : flex;
	  flex-direction   : column;
	  justify-content  : start;
	  @media (max-width : 420px) {
		height  : auto;
		padding : 30px 20px;
	  }

	  &.img {
		padding : 0;

		img {
		  border-radius : 24px;
		  max-width     : 100%;
		  height        : auto;
		  display       : block;
		}
	  }

	  .b__advantages-item-title {
		display       : flex;
		align-items   : center;
		margin-bottom : 15px;
	  }

	  .b__advantages-item-icon {
		margin-right     : 20px;
		border-radius    : 8px;
		background-color : #f9f9f9;
		width            : 50px;
		height           : 50px;
		position         : relative;

		img {
		  position  : absolute;
		  left      : 50%;
		  top       : 50%;
		  transform : translate(-50%, -50%);
		}
	  }

	  .b__advantages-item-titleh {
		max-width   : 190px;
		font-weight : 600;
		font-size   : 24px;
		@media (max-width : 420px) {
		  max-width : calc(100% - 70px);
		}
	  }
	}
  }
}