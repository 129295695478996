// Печатная products-color продукция


.products {
  margin           : 100px 0;
  background-color : $BGcolor;
  border-radius    : 50px;
  padding          : 40px;
  position         : relative;
  @media (max-width : 768px) {
	padding : 100px 25px;
  }
  @media (max-width : 425px) {
	padding : 30px 15px;
	margin  : 30px 0;
  }
}

.products > img {
  position : absolute;
  height   : 100%;
  right    : 0;
  top      : 0;
  opacity  : .3;
  @media (max-width : 680px) {
	display : none;
  }
}

.products_inner {
  z-index : 1;
}

.products_box {
  margin-top            : 20px;
  display               : grid;
  grid-template-columns : repeat(5, 1fr);
  grid-template-rows    : repeat(2, 120px);
  gap                   : 20px;
  @media (max-width : 1300px) {
	grid-template-columns : repeat(4, 1fr);
	grid-template-rows    : repeat(3, 120px);
  }
  @media (max-width : 1024px) {
	grid-template-columns : repeat(2, 1fr);
	grid-template-rows    : repeat(5, 120px);
  }
  @media (max-width : 680px) {
	grid-template-columns : repeat(1, 1fr);
  }
}

.products_box_mini {
  display               : grid;
  grid-template-columns : repeat(4, 1fr);
  grid-template-rows    : repeat(1, 120px);
  margin                : 20px 0;
  gap                   : 20px;
  @media (max-width : 1024px) {
	grid-template-columns : repeat(2, 1fr);
	grid-template-rows    : repeat(2, 120px);
  }
  @media (max-width : 680px) {
	grid-template-columns : repeat(1, 1fr);
  }
}

.products_block {
  padding          : 26px 0;
  background-color : #F9F9F9F9;
  border-radius    : 26px;
  position         : relative;
  display          : flex;
  align-items      : center;
  transition       : .3s;
  cursor           : pointer;
}

.products_block:hover {
  transition       : .3s;
  box-shadow       : lightgrey 0 0 15px;
  background-color : $BGcolor;
}

.products_block:hover .products_block_info > h3 {
  color      : $blue;
  transition : .3s;
}

.products_block svg > g {
  transition : .3s;
}

.products_block:hover svg > g {
  transition : .3s;
  opacity    : 1;
}

.products_block_svg {
  position : absolute;
  top      : 20%;
  left     : 20px;
}

.products_block_info {
  margin-left : 100px;
  text-align  : start;
}

.products_block_info_title {
  font-weight : 600;
  font-size   : 20px;
}



