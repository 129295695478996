// блок в каталоге "Где изготовить визитки?"
.manufacture {
	margin: 100px 0;
	position : relative;
}
.manufacture_con {
	padding: 40px;
	background-color: $BGcolor;
	border-radius: 52px;
	@media (max-width: 425px) {
		padding: 30px 20px 20px 20px;
	}
}
.manufacture > img {
	position : absolute;
	z-index : 1;
	right: 0;
	height : 100%;
	width : auto;
	@media (max-width: 1024px) {
		width: 100%;
	}
}
.manufacture_title {
	position : relative;
	z-index : 2;
}
.manufacture_inner {
	position : relative;
	z-index : 2;
}
.manufacture_inner p {
	margin: 20px 0;
	color: #171717;
	line-height : 22.4px;
}

.manufacture_dignity {
	height : 240px;
	display: grid;
	grid-template-columns: repeat(4, minmax(0,1fr));
	gap: 20px;
	@media (max-width: 1024px) {
		height : auto;
		grid-template-columns: repeat(2, minmax(0,1fr));
	}
	@media (max-width: 600px) {
		grid-template-columns: repeat(1, minmax(0,1fr));
	}
}
.manufacture_dignity_block {
	background-color : #F9F9F9;
	border-radius: 32px;
	padding: 20px;
	max-width: 325px;
	width : 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media (max-width: 1024px) {
		width : 100%;
	}
}
.manufacture_dignity_icon {
	position : relative;
	background-color: $BGcolor;
	width : 50px;
	height : 50px;
	border-radius: 8px;
	&::after {
		position : absolute;
		left: 50%;
		top: 50%;
		width : 24px;
		height : 24px;
		content: "";
		transform: translate(-50%,-50%);
	}
	@media (max-width: 1024px) {
		margin-bottom : 50px;
	}
}
.manufacture_i_info::after {
	background: url("../images/info.svg") no-repeat;
}
.manufacture_i_pallete::after {
	background: url("../images/palette.svg") no-repeat;
}
.manufacture_i_paint::after {
	background: url("../images/paint.svg") no-repeat;
}
.manufacture_i_coins::after {
	background: url("../images/coins.svg") no-repeat;
}
.manufacture_dignity_title {
	font-size : 24px;
	font-weight : 600;
	margin-bottom : 15px;
	color: $black;
}
.manufacture_dignity_text {
	color: $gray;
	line-height : 22.4px;
	@media (max-width: 1024px) {
		font-size : 14px;
	}
}


.manufacture_content {
	display: flex;
	margin-top: 30px;
	@media (max-width: 1024px) {
		font-size : 14px;
		align-items: center;
	}
	@media (max-width: 900px) {
		display: block;
		gap: 20px;
		flex-direction: column;
		justify-content: center;
	}
}

.manufacture_content_inner {
	border-radius: 60px;
	background-color: #F9F9F9;
	padding: 40px;
}
.manufacture_content_inner >p:first-child {
	margin-top: 0;
}
.manufacture_content_inner >p:last-child {
	margin-bottom: 0;
}
.manufacture_content >img {
	border-radius: 60px;
	@media (max-width: 600px) {
		width: 100%;
		object-fit: cover;
	}
}
.manufacture_list {
	display: flex;
	justify-content: space-between;
	@media (max-width: 600px) {
		flex-direction: column;
		gap: 10px;
	}
}
.manufacture_list_ul {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;
	@media (max-width: 600px) {
		width: 100%;
	}
}
.manufacture_list_ul li {
	position : relative;
	color: #171717;
	margin-left : 16px;
	&::after {
		position : absolute;
		content: "";
		width: 6px;
		height : 6px;
		background-color: $blue;
		border-radius: 3px;
		left: -19px;
		top: 50%;
		transform: translateY(-50%);
	}

}
