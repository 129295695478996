// nav
.nav_inner {
  @media screen and (min-width : 1101px) {
	position         : relative;
	background-color : $BGcolor;
	border-radius    : 27px;
	width            : 100%;
	height           : 54px;
	display          : flex;
	align-items      : center;
	justify-content  : space-between;
	z-index          : 3;
	transition       : .3s;
  }
  @media screen and (max-width : (1101 - 0.02) * 1px) {
	display         : flex;
	flex-flow       : column nowrap;
	justify-content : flex-start;
	align-items     : stretch;
	text-align      : center;
	height          : auto;
	order           : 0;
  }
}

.nav_menu {
  display     : flex;
  align-items : center;
  cursor      : pointer;
}

.nav_menu_logo {
  display : none;
}

.nav_menu_main {
  padding          : 16px 39px 16px 68px;
  background-color : $blue;
  border-radius    : 27px;
  color            : #FFF;
  user-select      : none;
  @media screen and (max-width : (1101 - 0.02) * 1px) {
	text-align      : center;
	padding         : 16px;
	display         : flex;
	flex-flow       : row nowrap;
	justify-content : center;
	align-items     : center;
	z-index         : 40;
  }
}

.nav_menu_main.active {
  fill       : $blue;
  transition : .3s;
}

.nav_menu_main_items {
  background-color : $BGcolor;
  border-radius    : 0 0 32px 32px;
  @media screen and (min-width : 1101px) {
	width      : 407px;
	display    : none;
	position   : absolute;
	top        : 52px;
	left       : 0;
	text-align : start;
	padding    : 20px 40px 30px 30px;
  }
  @media screen and (max-width : (1101 - 0.02) * 1px) {
	position   : relative;
	padding    : 40px 16px 20px;
	display    : none;
	box-sizing : border-box;
	margin-top : -20px;
	z-index    : 10;
  }
}

.nav_menu_main_items.active {
  transition : .3s;
  display    : block;
  box-shadow : 0 10px 15px rgba(200, 200, 200, 0.3), -20px 13px 15px rgba(200, 200, 200, .2), 20px 13px 15px rgba(200, 200, 200, 0.2);
}

.nav_menu_main_items > li {
  margin-bottom : 15px;
}

.nav_menu_main_items > li:last-child {
  margin-bottom : 0;
}

.nav_menu_main_items_item {
  cursor : pointer;
}

.nav_menu_main_items_item:hover {
  transition : .3s;
  color      : $blue;
}

.nav_menu_main_acc {
  cursor   : pointer;
  color    : $black;
  position : relative;
}

.nav_menu_main_acc:hover {
  color      : $blue;
  transition : .3s;
}

.nav_menu_main_acc:after {
  content    : url("../images/mini-arrow.svg");
  position   : absolute;
  top        : 0;
  right      : 0;
  transition : .3s;
}

.nav_menu_main_acc.active:after {
  content    : url("../images/mini-arrow-active.svg");
  transition : .3s;
}

.nav_menu_main_acc.active .nav_menu_main_acc_block {
  color       : $blue;
  margin-left : 20px;
  display     : block;
  transition  : transform .3s;
  transform   : scaleY(1);
  box-shadow  : none;
}

.nav_menu_main_acc_block {
  display    : none;
  transform  : scaleY(0);
  transition : transform .3s;
}

.nav_menu_main_acc_items {
  color      : $black !important;
  margin     : 13px 0;
  transition : .3s;
}

.nav_menu_main.active .nav_menu_burger {
  transition       : transform .3s;
  background-color : transparent;
}

.nav_menu_main.active span::after {
  transform  : scale(1) rotateZ(45deg) translate(3px, 6px);
  transition : transform .3s;
}

.nav_menu_main.active span::before {
  transform  : scale(1) rotateZ(-45deg) translate(1px, -4px);
  transition : transform .3s;
}


.nav_menu_search {
  margin-right : 40px;
}

.nav_menu.nav_menu_search {
  @media screen and (max-width : (1101 - 0.02) * 1px) {
	position     : relative;
	margin-right : 0;
  }
}

.nav_menu_search_icon {
  transition  : .3s;
  position    : relative;
  width       : 22px;
  height      : 22px;
  user-select : none;

  &::before {
	transition      : .3s;
	content         : "";
	position        : absolute;
	width           : 22px;
	height          : 22px;
	left            : 50%;
	top             : 50%;
	transform       : translate(-50%, -50%);
	background      : url("../images/search.svg");
	background-size : contain;
	@media screen and (max-width : (1101 - 0.02) * 1px) {
	  position  : static;
	  display   : block;
	  transform : none;
	  width     : 18px;
	  height    : 18px;
	}
  }

  span {
	@media screen and (min-width : 1101px) {
	  display    : none;
	  visibility : hidden;
	}
  }

  @media screen and (max-width : (1101 - 0.02) * 1px) {
	display         : flex;
	margin-top      : 20px;
	width           : 100%;
	text-align      : center;
	flex-flow       : row nowrap;
	justify-content : center;
	gap             : 2px;
	align-items     : center;
  }
}

.nav_menu_search.active .nav_menu_search_icon {
  &::after {
	transition : .3s;
	background : url("../images/search-active.svg");
  }
}

.nav_menu_search_block {
  position         : absolute;
  transform        : scale(0);
  transition       : all .3s;
  background-color : $BGcolor;
  padding          : 10px;
  width            : 286px;
  right            : 0;
  transform-origin : right center;
  top              : 45px;
  border-radius    : 0 0 32px 32px;
  box-shadow       : 0 10px 15px rgba(200, 200, 200, 0.3), -20px 20px 15px rgba(200, 200, 200, .2), 20px 20px 15px rgba(200, 200, 200, 0.2);
  @media screen and (max-width : (1101 - 0.02) * 1px) {
	width            : 100%;
	border-radius    : 20px;
	transform-origin : center;
	box-sizing       : border-box;
  }
}

.nav_menu_search.active .nav_menu_search_block {
  transform  : scale(1);
  transition : all .3s;

  & input[type="search"] {
	padding-left     : 15px;
	border-radius    : 24px;
	background-color : #F9F9F9;
	cursor           : text;
	width            : 100%;
	height           : 46px;
  }
}

.nav_menu_burger {
  margin-right     : 10px;
  position         : relative;
  width            : 14px;
  height           : 2px;
  border-radius    : 1px;
  background-color : $BGcolor;

  &::before, &::after {
	content          : "";
	position         : absolute;
	height           : 2px;
	right            : 0;
	border-radius    : 1px;
	background-color : $BGcolor;
	width            : 20px;
  }
}

.nav_menu_burger::before {
  top : 5px;
}

.nav_menu_burger::after {
  bottom : 5px;
}


// новый nav
.mod-menu {
  display     : flex;
  align-items : center;
  @media screen and (max-width : (1101 - 0.02) * 1px) {
	display         : flex;
	flex-flow       : column nowrap;
	justify-content : center;
	align-items     : center;
	margin-top      : 20px;
  }
}

.mod-menu > li {
  margin : 0 12px;
  @media screen and (max-width : (1101 - 0.02) * 1px) {
	margin : 10px 0;
	&:first-child {
	  margin-top : 0;
	}
	&:last-child {
	  margin-bottom : 0;
	}
  }
}

.nav-item > ul {
  display : none;
}

.nav-item.parent {
  position       : relative;
  display        : flex;
  flex-direction : column-reverse;
}

.nav-item.parent > a::after {
  transition : .4s;
  content    : "";
  background : url("../images/mini-arrow.svg") no-repeat;
  width      : 9px;
  height     : 5px;
  position   : absolute;
  right      : -15px;
  margin-top : 1px;
  top        : 50%;
  transform  : translateY(-50%);
}

.nav-item.parent:hover > a::after {
  transition : .3s;
  transform  : rotate(180deg);
  background : url("../images/mini-arrow-active.svg");
  margin-top : -1px;
}

.nav-item.parent:hover > ul {
  display : flex;
}

.nav-item.parent > ul > li:first-child {
  margin-top   : 15px;
  margin-right : 30px;
}

.nav-item.parent > ul {
  box-shadow       : 0 20px 20px rgba(200, 200, 200, .3), -5px 25px 20px 0px rgba(200, 200, 200, .2), 5px 25px 20px 0px rgba(200, 200, 200, .2);
  position         : absolute;
  top              : 18px;
  left             : -30px;
  border-radius    : 0 0 32px 32px;
  z-index          : 3;
  padding          : 0 30px 30px;
  background-color : $BGcolor;
  flex-direction   : column;
  gap              : 15px;
}

.nav-item {
  position   : relative;
  transition : .3s;
}

.nav-item:hover a {
  color      : $blue;
  transition : .3s;
}

.nav-item.parent > ul a:hover {
  color      : $blue !important;
  transition : .3s;
}

.nav-item.parent > ul a {
  color : $black !important;
}
