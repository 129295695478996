
.portfolio {
  margin : 170px auto;
  @media (max-width : 425px) {
    margin : 50px auto;
  }
}

.portfolio_slider {
  margin : 50px 0;
  @media (max-width : 425px) {
	.portfolio_slider img {
	  width : 100%;
	}
  }
}

.portfolio_block {
  display        : flex;
  flex-direction : column;
}

.portfolio_block_name {
  display          : flex;
  justify-content  : space-between;
  align-items      : center;
  transition       : .3s;
  padding          : 20px;
  height           : 112px;
  border-radius    : 26px;
  background-color : #FFF;
}

.portfolio_block_name:hover {
  box-shadow : lightgrey 0 0 15px;
  transition : .3s;
  color      : $blue;
}

.portfolio_block_image {
  width         : auto;
  height        : auto;
  border-radius : 26px;
  z-index       : 1;
  //@media (max-width : 768px) {
	//width     : 100%;
	//max-width : 100%;
	//height    : auto !important;
  //}
}

.portfolio_block_title {
  font-weight : 500;
  font-size   : 20px;
  font-family : $mainFonts;
  @media (max-width : 768px) {
	max-width : 500px;
  }
}

.portfolio_block_arrow {
  display          : flex;
  background-color : #f1f1f1;
  border-radius    : 50%;
  padding          : 9px;
}

.portfolio_custom_pagination {
  text-align : center;
}

.portfolio_custom_pagination div {
  display       : inline-block;
  width         : 4px;
  height        : 4px;
  background    : gray;
  border-radius : 50%;
  margin        : 0 5px;
  cursor        : pointer;
}

.portfolio_custom_pagination .active {
  background    : $blue;
  width         : 40px;
  border-radius : 4px;
}


#prev {
  transform : rotateY(180deg);
  stroke    : $blue;
}

#next, #prev {
  border           : 1px solid $blue;
  background-color : transparent;
}

#next, #prev.swiper-button-disabled {
  background-color : $blue;
}

#next, #prev.swiper-button-disabled svg {
  fill   : $blue;
  stroke : $BGcolor;
}





