@import "helpers/config-main";
@import "helpers/normalize";
@import "helpers/sprite-svg";
@import "helpers/icon";

* {
	box-sizing : border-box
}

* h1, * h2, * h3, * h4, * p, * ul, * li, body, span {
	padding : 0;
	margin  : 0;
}

body {
	position         : relative;
	background-color : #F4F4F4;
	font-family      : $mainFonts;
	font-weight      : 400;
	font-size        : 16px;
	color            : $black;

	@media (max-width : 1024px) {
		font-size : 16px;
	}
	@media (max-width : 425px) {
		font-size : 14px;
	}
}

img {
	user-select : none;
}

input, button, textarea {
	border     : none;
	background : none;
	outline    : none;
	font-size  : 16px;
	color      : $black;
	cursor     : pointer;
}

a {
	cursor          : pointer;
	text-decoration : none;
	color           : $black;
}

ul, li, ol {
	padding    : 0;
	margin     : 0;
	list-style : none;
}

.b__wrapper {
	padding   : 0 40px;
	max-width : 1440px;
	margin    : 0 auto;
	@media (max-width : 768px) {
		padding : 0 25px;
	}
	@media (max-width : 425px) {
		padding : 0 15px;
	}
}

.title {
	font-family   : $titleFonts;
	font-size     : 76px;
	font-weight   : 500;
	margin-bottom : 50px;
	@media (max-width : 1024px) {
		font-size : 60px;
	}
	@media (max-width : 768px) {
		font-size   : 55px;
		line-height : 1.2;
	}
	@media (max-width : 560px) {
		font-size : 42px;
	}
	@media (max-width : 425px) {
		width         : auto;
		max-width     : 329px;
		font-size     : 42px;
		margin-bottom : 20px;
		& > img {
			width  : 120px;
			height : 30px;
		}
	}

	.title_color {
		width         : 180px;
		height        : 52px;
		border-radius : 26px;
		display       : inline-block;
		@media (max-width : 600px) {
			width  : 90px;
			height : 26px;
		}
	}
}

.span_blue {
	color : $blue;
}

.swiper-wrapper {
	padding : 10px 0;
}

@media (max-width : 768px) {
	.desktop-mask {
		display : none;
	}
	.mobile_mask {
		display : block;
	}
	#logo_desktop {
		display : none;
	}
}

@media (max-width : 320px) {
	.title, .products_inner .title, .work_inner .title, .contact_title, .typography_name, .portfolio_title, .services_title .title, .advantages_inner .title, .portfolio_title .title, .typography_name .title {
		width : 250px;
	}
}

// Load Mixins and Classes
// -----------------------

@import "modules/modules";

@import "modules/header";
@import "modules/nav";
@import "modules/breadcrumbs";

@import "modules/modal";
@import "modules/formcart"; // корзина
@import "modules/footer";

// главная
@import "modules/homeslider"; // слайдер
@import "modules/typography"; // Типография
@import "modules/products"; // Печатная продукция
@import "modules/howwework"; // Как мы работаем?  //// (catalog) Как сделать заказ
@import "modules/aboutus"; // Коротко о нас
@import "modules/services"; // Услуги полиграфии в СПБ
@import "modules/advantages"; // Почему выбирают нас?
@import "modules/portfolio"; // Примеры наших работ
@import "modules/clients"; // Наши клиенты

// карточка товара
@import "modules/cardproduct"; // карточка товара, информация, и нижний таб

// каталог
@import "modules/catalog"; // баннер и товары
@import "modules/ideas"; // Сервис: от идеи до доставки
@import "modules/manufacture"; // Где изготовить визитки?
@import "modules/howwework"; // Как мы работаем?  //// (catalog) Как сделать заказ
@import "modules/ourdirections"; // Наши направления?  //// (catalog) Как сделать заказ
@import "modules/request"; // Модуль корзины
// Статьи
@import "modules/article";
// vendors
@import "vendors/slick";
@import "vendors/magnific-popup";
// Страницы
@import "pages/abouts";
@import "pages/com-contact";
@import "pages/faq";
@import "pages/forbusines";
@import "pages/erros";




