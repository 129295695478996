// блок Типография на главной странице


.typography {
	margin: 70px 0 100px;
}

.typography_name {
	max-width   : 600px;
	line-height : 1.2;
}
.typography_name_title .title{
	@media (max-width : 425px) {
		width     : auto;
		max-width : 329px;
	}
}


.typography_content {
	object-fit: cover;
	margin                : 50px 0;
	display               : grid;
	grid-template-columns : repeat(4, minmax(0, 1fr));
	gap                   : 20px;
	@media (max-width: 1300px) {
		grid-template-columns : repeat(3, minmax(0, 1fr));
	}
	@media (max-width: 925px) {
		grid-template-columns : repeat(2, minmax(0, 1fr));
	}
	@media (max-width: 600px) {
		grid-template-columns : repeat(1, minmax(0, 1fr));
	}
}




.typography_block {
	display        : flex;
	flex-direction : column;
}
.typography_block img {
	@media (max-width : 425px) {
		width      : 100%;
		height     : 100%;
		max-width  : 100%;
		max-height : 100%;
	}

}

.typography_block_name {
	display          : flex;
	justify-content  : space-between;
	align-items      : center;
	transition       : .3s;
	padding          : 0 20px;
	height           : 74px;
	border-radius    : 26px;
	background-color : #ffffff;
	&:hover {
		box-shadow : lightgrey 0 0 15px;
		transition : .3s;
		color      : $blue;
	}

	@media (max-width: 425px) {
		width : 100%;
	}
	@media (max-width : 360px) {
		max-width : 325px;
		width     : 100%;
	}
}


.typography_block_image {
	border-radius : 26px;
	z-index       : 1;
	max-width     : 325px;
	max-height    : 220px;
	@media (max-width: 1439px) {
		max-width     : none;
		max-height    : none;
		width     : auto;
		height    : auto;
	}
	@media (max-width : 360px) {
		width  : 100%;
		height : 100%;
	}

}


.typography_block_title {
	font-weight : 500;
	font-size   : 20px;
	font-family : $mainFonts;
	@media (max-width: 1024px) {
		font-size : 18px;
	}
}

.typography_block_arrow {
	width            : 34px;
	height           : 34px;
	border-radius    : 50%;
	background-color : #F9F9F9;
	padding          : 9px;
	position         : relative;
}
.typography_block_arrow_icon {
	position  : absolute;
	top       : 50%;
	left      : 50%;
	transform : translate(-50%, -50%);
	width     : 16px;
	height    : 16px;
	cursor    : pointer;
}
.typography_block_arrow_icon_arrow {
	position         : absolute;
	top              : 50%;
	transform        : translateY(-50%);
	width            : 95%;
	height           : 1px;
	background-color : $black;

	&::before {
		content          : '';
		position         : absolute;
		width            : 50%;
		height           : 1px;
		right            : 0;
		background-color : black;
	}

	&::after {
		content          : '';
		position         : absolute;
		width            : 50%;
		height           : 1px;
		right            : 0;
		background-color : black;
	}

	&::before {
		top       : 3px;
		transform : rotate(-45deg);
	}

	&::after {
		top       : -4px;
		transform : rotate(45deg);
	}
}
