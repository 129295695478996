.b__ourdirections {
  .b__ourdirections-title {
	font-family   : "Oswald", sans-serif;
	font-size     : 76px;
	font-weight   : 500;
	margin-bottom : .65em;

	.title_color {
	  width          : 180px;
	  height         : 52px;
	  border-radius  : 26px;
	  display        : inline-block;
	  vertical-align : middle;
	  @media (max-width : 420px) {
		width  : 90px;
		height : 26px;
	  }
	}

	@media (max-width : 800px) {
	  font-size : 42px;
	}
	@media (max-width : 420px) {
	}
  }

  .b__ourdirections-items {
	margin-top            : 30px;
	text-align            : left;
	display               : grid;
	grid-template-columns : repeat(5, 1fr);
	gap                   : 20px;

	@media (max-width : 1200px) {
	  grid-template-columns : repeat(2, 1fr);
	}
	@media (max-width : 800px) {
	  grid-template-columns : repeat(2, 1fr);
	}
	@media (max-width : 420px) {
	  grid-template-columns : repeat(1, 1fr);
	}
  }

  .b__ourdirections-item-title {
	padding       : 20px;
	background    : rgba(249, 249, 249, 1);
	box-shadow    : 0 4px 30px 0 rgba(0, 0, 0, 0.12);
	border-radius : 32px;
	font-size     : 13px;
	color         : #7B7B7B;
	@media (max-width : 420px) {
	  font-size : 16px;
	}

	&:hover {
	  background : #FFF;
	}

	h3 {
	  color  : #0B0F14;
	  margin : 0 0 10px;

	  a {
		text-decoration : none;
	  }
	}

	ul {
	  margin : 0;
	}
  }

  .advantages_block_items_text {
	img {
	  border-radius : 24px;
	  max-width     : 100%;
	  height        : auto;
	  display       : block;
	  box-shadow    : 0 4px 30px 0 rgba(0, 0, 0, 0.12);
	  @media (max-width : 420px) {
		width : 100%;
	  }
	}
  }
}