[class^="b-icon-"],
[class*=" b-icon-"] {
  display        : inline-block;
  vertical-align : top;
}

.b-icon-telegram {
  @include svg-telegram;
}
.b-icon-whatsap {
  @include svg-whatsap;
}
.b-icon-left-white {
  @include svg-left-white;
}
.b-icon-left-blue {
  @include svg-left-blue;
}
.b-icon-rigt-blue {
  @include svg-right-blue
}
.b-icon-rigt-white {
  @include svg-right-white
}

