@mixin svg-left-blue() {
	background: url('../images/sprite.svg?v1728474771217') 0 70.58823529411765% no-repeat;
	width: 20px;
	height: 20px;
}
@mixin svg-left-white() {
	background: url('../images/sprite.svg?v1728474771217') 40.816326530612244% 70.58823529411765% no-repeat;
	width: 20px;
	height: 20px;
}
@mixin svg-right-blue() {
	background: url('../images/sprite.svg?v1728474771217') 100% 0 no-repeat;
	width: 20px;
	height: 20px;
}
@mixin svg-right-white() {
	background: url('../images/sprite.svg?v1728474771217') 100% 58.8235294117647% no-repeat;
	width: 20px;
	height: 20px;
}
@mixin svg-telegram() {
	background: url('../images/sprite.svg?v1728474771217') 55.55555555555556% 0 no-repeat;
	width: 24px;
	height: 24px;
}
@mixin svg-verification-checkmark() {
	background: url('../images/sprite.svg?v1728474771217') 0 100% no-repeat;
	width: 10px;
	height: 10px;
}
@mixin svg-whatsap() {
	background: url('../images/sprite.svg?v1728474771217') 0 0 no-repeat;
	width: 25px;
	height: 24px;
}
