
.footer {
  margin : 40px auto 20px;
}

.footer_inner {
  justify-content  : space-evenly;
  border-radius    : 40px;
  background-color : $BGcolor;
  padding          : 24px 0;
  display          : flex;
  align-items      : center;
  @media (max-width : 768px) {
	flex-direction  : column;
	justify-content : center;
  }


}

.footer_logo {
  display     : flex;
  align-items : center;
  max-width   : 400px;
}

.footer_logo_info {
  max-width   : 155px;
  font-size   : 12px;
  margin-left : 12px;
  @media (max-width : 768px) {
	display : none;
  }
}

.footer_li {
  font-size : 12px;

  .nav {
	position : static !important;
	@media (max-width : 428px) {
	  margin-top : 0;
	}
  }

  @media (max-width : 768px) {
	margin     : 5px auto;
	text-align : center;
	width      : auto;
	& a {
	  text-decoration : underline;
	}
	&:first-child {
	  margin : 0 0 50px 0;
	}
	&:last-child {
	  margin : 50px 0 0 0;
	}
  }
  @media (max-width : 425px) {
	&:first-child {
	  margin : 0 0 20px;
	}
	&:last-child {
	  margin : 20px 0 0;
	}
  }

  .mod-menu {
	display : block;

	li {
	  & + li {
		margin-top : 2px;
	  }

	  a {
		color      : $blue;
		transition : .3s;

		&:hover {
		  transition      : .3s;
		  text-decoration : underline;
		}
	  }
	}
  }
}

.footer_copyright {
  color     : $description;
  font-size : 12px;
}

.footer_devs {
  position     : relative;
  padding-left : 39px;

  img {
	left     : 0;
	position : absolute;
	top      : -2px;
  }

  p {
	margin : 0;

	& + p {
	  margin-top : 2px;
	}
  }

  a {
	color      : $blue;
	transition : .3s;

	&:hover {
	  transition      : .3s;
	  text-decoration : underline;
	}
  }
}