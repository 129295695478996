// слайдер на главной странице

.slider {
  margin      : 50px auto 70px;
  user-select : none;
  z-index     : 2;

  @media screen and (max-width : (1101 - 0.02) * 1px) {
	margin-top : 20px;
  }
  @media screen and (max-width : 480px) {
	margin-bottom : 20px;
  }
}

.slider_inner {
  display     : flex;
  align-items : center;
  width       : 100%;
  transition  : transform .5s ease;
  padding     : 20px 5px;
}

.slider_block > .swiper-slide {
  height : 490px;
  @media (max-width : 425px) {
	height  : 395px;
	padding : 20px;
  }
}


.slider_block_container {
  background-color : $BGcolor;
  border-radius    : 32px;
  padding          : 30px 34px 30px 30px;
  position         : relative;
  display          : flex !important;
  flex-direction   : column;
  flex-flow        : row wrap;
  justify-content  : start;
  align-content    : space-between;
  transition       : .3s;

  &:hover {
	transition : .3s;
	box-shadow : 0 0 15px rgba(0, 0, 0, .12);
  }
}


.slider_image {
  border-radius : 32px;
  position      : absolute;
  bottom        : 0;
  right         : 0;
  z-index       : 1;
}

.slider_title {
  font-family : $titleFonts;
  font-size   : 30px;
  font-weight : 600;
  position    : relative;
  z-index     : 3;
  @media (max-width : 425px) {
	width     : 260px;
	font-size : 24px;
  }

  b {
	color       : #0095D8;
	font-weight : 900;
  }
}


.slider_button {
  position         : absolute;
  z-index          : 3;
  border-radius    : 8px;
  width            : 50px;
  height           : 50px;
  padding          : 16px 15px;
  transition       : .3s;
  background-color : #F1F1F1;
  left             : 30px;
  bottom           : 30px;
  display          : none;

  a & {
	display : block;
  }

  a:hover & {
	background-color : $blue;
	transition       : .3s;

	&::after {
	  transition : .3s;
	  background : url("../images/arrow-active.svg") no-repeat;
	}
  }

  @media (max-width : 425px) {
	background-color : $blue;
  }

  &::after {
	transition : .3s;
	position   : absolute;
	left       : 50%;
	top        : 50%;
	transform  : translate(-50%, -50%);
	content    : "";
	background : url("../images/arrow.svg") no-repeat;
	width      : 20px;
	height     : 20px;
  }
}


.slider_custom_pagination {
  text-align : center;
}

.slider_custom_pagination div {
  display       : inline-block;
  width         : 4px;
  height        : 4px;
  background    : gray;
  border-radius : 50%;
  margin        : 0 5px;
  cursor        : pointer;

}

.slider_custom_pagination .active {
  background    : $blue;
  width         : 40px;
  border-radius : 4px;
}
