// Модуль Коротко о нас
.aboutus {
  margin : 180px 0 100px;
  @media (max-width : 425px) {
	margin : 30px 0;
  }
}

.aboutus_inner {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  height          : 100%;
  width           : 100%;
  @media (max-width : 768px) {
	display : block;
  }
  @media (max-width : 425px) {
	display : block;
	margin  : 60px 0;
  }
}

.aboutus_title {
  max-width   : 360px;
  line-height : 1;
  @media (max-width : 425px) {
	max-width : 200px;
  }

  .title {
	@media (max-width : 320px) {
	  width : 200px;
	}
  }
}

.aboutus_info {
  display        : flex;
  flex-direction : column;
  @media (max-width : 1024px) {
	width : 50%;
  }
  @media (max-width : 768px) {
	width         : 100%;
	margin-bottom : 40px;
  }
}

.aboutus_info a {
  transition : .3s;
  color      : $blue;
}

.aboutus_info a:hover {
  transition      : .3s;
  text-decoration : underline;
}

.aboutus_info_block {
  max-width : 520px;
}

.aboutus_info_block_text {
  margin : 62px 0 50px 0;
  @media (max-width : 1024px) {
	margin : 35px 0 20px;
  }
}

.aboutus_info_block_text li {
  color : $description;
}

.aboutus_info_block_text li:nth-child(2n + 2) {
  margin : 20px 0;
}

.aboutus_achievements {
  @media (max-width : 1024px) {
	width : 50%;
  }
  @media (max-width : 768px) {
	width : 100%;
  }
  @media (max-width : 425px) {
	margin-top : 40px;
  }
}

.aboutus_achievements_image {
  display       : flex;
  align-content : center;
  @media (max-width : 425px) {
	display : block;
  }
}

.aboutus_achievements_image_block:first-child {
  margin-right : 20px;
  @media (max-width : 425px) {
	margin : 0 0 20px 0;
  }
}

.aboutus_achievements_image_block_mini > img {
  height : 100%;
  @media (max-width : 1024px) {
	height : 100%;
  }
}

.aboutus_achievements_image_block img {
  border-radius : 32px;
  @media (max-width : 1300px) {
	width  : 100%;
	height : 100%;
  }
}

.aboutus_achievements_info {
  margin-top            : 15px;
  align-content         : center;
  display               : grid;
  grid-template-columns : repeat(3, 1fr);
  gap                   : 20px;
  @media (max-width : 425px) {
	display    : block;
	margin-top : 0;
  }
}

.aboutus_achievements_info_block {
  padding          : 28px 0;
  max-height       : 136px;
  text-align       : center;
  background-color : $BGcolor;
  border-radius    : 32px;
  @media (max-width : 425px) {
	margin          : 20px 0;
	width           : 100%;
	display         : flex;
	align-items     : center;
	justify-content : center;
  }
}

.aboutus_achievements_info_block br {
  display : none;
  @media (max-width : 425px) {
	display : block;
  }
}

@media (max-width : 425px) {
  .aboutus_achievements_info_block > p {
	margin-left : 12px;
	text-align  : start;
	width       : 62px;
  }
  .aboutus_achievements_info_block:first-child > p {
	width : 72px;
  }

}

.aboutus_achievements_info_title {
  color       : $blue;
  font-size   : 42px;
  font-family : $titleFonts;
  font-weight : 500;
  @media (max-width : 1024px) {
	font-size : 32px;
  }
  @media (max-width : 768px) {

	font-size : 42px;
  }
}

@media (max-width : 425px) {
  .aboutus_achievements_info_text {
	font-size : 16px;
  }
}

