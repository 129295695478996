// header

.header {
	@media screen and (max-width : (1101 - 0.02) * 1px) {
		position : relative;
	}
}

.header_inner {
	padding         : 20px 0;
	display         : flex;
	align-items     : center;
	justify-content : space-between;
	@media screen and (max-width : (1101 - 0.02) * 1px) {
		display : contents;
	}
}

.header_logo {
	display     : flex;
	align-items : center;
	@media screen and (max-width : (1101 - 0.02) * 1px) {
		display    : none;
		visibility : hidden;
	}
}

.header_logo_info {
	display    : none;
	visibility : hidden;
	@media screen and (min-width : 1201px) {
		max-width   : 155px;
		font-size   : 12px;
		display     : block;
		visibility  : visible;
		margin-left : 12px;
	}
}

.header_location {
	font-weight : 500;
	font-size   : 14px;
	text-align  : center;
	max-width   : 305px;
	@media screen and (max-width : (1101 - 0.02) * 1px) {
		display    : none;
		visibility : hidden;
	}
}

.header_mail {
	font-weight : 500;
	font-size   : 14px;

	&:hover {
		color           : $blue;
		text-decoration : underline;
		transition      : .3s;
	}
}

.header_social {
	display         : flex;
	align-items     : center;
	justify-content : space-between;
	width           : 85px;
	@media screen and (max-width : (1101 - 0.02) * 1px) {
		justify-content : center;
		gap             : 20px;
		width           : auto;
		margin-top      : 20px;
	}
}

.header_social_bg {
	position        : relative;
	width           : 32px;
	height          : 32px;
	border-radius   : 6px;
	display         : flex;
	align-items     : center;
	justify-content : center;
}

.header_social_icon {
	position : absolute;
	left     : 8px;
	top      : 8px;
}

.header_social_tg {
	transition       : .3s;
	background-color : $blue;

	& > svg {
		fill   : $BGcolor;
		width  : 14px;
		height : 16px;
	}

	&:hover {
		transition : .3s;
		box-shadow : $blue 0 0 15px;
	}
}

.header_social_wa {
	transition       : .3s;
	background-color : $BGcolor;

	& > svg {
		fill   : #24D366;
		width  : 16px;
		height : 16px;
	}

	&:hover {
		transition : .3s;
		box-shadow : lightgreen 0 0 15px;
	}
}

.header_tel {
	@media screen and (min-width : 1101px) {
		text-align : end;
	}
	@media screen and (max-width : (1101 - 0.02) * 1px) {
		margin-top : 20px;
	}
}

.header_tel_title {
	font-size   : 18px;
	font-weight : 700;
	@media (max-width : 1024px) {
		font-size : 14px;
	}
}

.header_tel_number:hover {
	color           : $blue;
	text-decoration : underline;
	transition      : .3s;
}

#logo_mobile {
	display : none;
	@media (max-width : 768px) {
		display : block;
		z-index : 77;
	}
}

@keyframes mobileMenuShow {
	0% {
		opacity   : 0;
		transform : translateY(10px);
	}
	100% {
		opacity   : 1;
		transform : translateY(0);
	}
}

@keyframes mobileMenuHide {
	0% {
		opacity   : 1;
		transform : translateY(0);
	}
	100% {
		opacity   : 0;
		transform : translateY(10px);
	}
}

.header-box {
	@media screen and (max-width : (1101 - 0.02) * 1px) {
		position        : absolute;
		border-radius   : 12px;
		top             : -10px;
		left            : -10px;
		z-index         : 100;
		right           : -10px;
		box-shadow      : 0 3px 7px 0 rgba(0, 0, 0, 0.08);
		background      : #FFF;
		padding         : 68px 10px 30px 10px;
		flex-flow       : column nowrap;
		justify-content : flex-start;
		align-items     : stretch;
		display         : none;
		animation       : mobileMenuHide .3s ease;

		&.is-open {
			display : flex;
		}
		&.is-animation {
			animation : mobileMenuShow .3s ease;
		}
	}
}

.header-box__footer {
	@media screen and (max-width : (1101 - 0.02) * 1px) {
		order      : 1;
		margin-top : 67px;
		text-align : center;
	}
	@media screen and (min-width : 1101px) {
		display : contents;
	}
}

.header-box-mobile {
	@media screen and (max-width : (1101 - 0.02) * 1px) {
		margin-top      : 15px;
		position        : relative;
		z-index         : 150;
		display         : flex;
		flex-flow       : row nowrap;
		justify-content : space-between;
		align-items     : center;
	}
	@media screen and (min-width : 1101px) {
		display    : none;
		visibility : hidden;
	}

}

.header-box-mobile__logo {
	@media screen and (max-width : (1101 - 0.02) * 1px) {
		width : 150px;
		a {

			display         : block;
			text-decoration : none;

			img {
				max-width      : 100%;
				height         : auto;
				vertical-align : top;
			}
		}
	}
}

.header-box-mobile__trigger {
	position                    : relative;
	box-sizing                  : border-box;
	margin-left                 : auto;
	padding                     : 0;
	cursor                      : pointer;
	vertical-align              : top;
	text-decoration             : none;
	opacity                     : 1;
	border                      : 0;
	outline                     : 0;
	-webkit-tap-highlight-color : transparent;
	background                  : $blue;
	width                       : 39px;
	display                     : flex;
	height                      : 30px;
	flex-flow                   : column nowrap;
	justify-content             : center;
	align-items                 : center;
	isolation                   : isolate;
	border-radius               : 8px;

	&::after {
		content  : "";
		z-index  : -1;
		position : absolute;
		top      : -10px;
		bottom   : -10px;
		right    : -10px;
		left     : -10px;
	}

	span {
		position : relative;
		display  : block;
		width    : 20px;
		height   : 10px;

		i,
		&::after,
		&::before {
			content       : "";
			position      : absolute;
			top           : 50%;
			display       : block;
			width         : 100%;
			right         : 0;
			height        : 1px;
			transition    : transform .3s ease;
			transform     : rotate(0deg);
			border-radius : 1px;
			background    : #FFF;
		}

		i {
			width      : 50%;
			margin-top : 0.5px;
			transform  : translateY(-50%);
			transition : opacity .3s ease, transform .3s ease;
		}

		&::before {
			margin-top : -5px;
		}

		&::after {
			margin-top : 5px;
		}
	}

	&.is-open {
		span {
			i {
				transform : translateY(-50%) translateX(-50%);
				opacity   : 0;
			}

			&::before {
				margin-top : -1px;
				transform  : rotate(45deg);
			}

			&::after {
				margin-top : -1px;
				transform  : rotate(-45deg);
			}
		}
	}
}
