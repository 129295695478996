/* Message */
.mfp-close {
  width           : 30px;
  height          : 30px;
  color           : white;
  font-size       : 28px;
  position        : absolute;
  top             : 15px;
  right           : -35px;
  display         : flex;
  align-items     : flex-end;
  justify-content : center;
  opacity         : .6;
  transition      : .3s;
}

.mfp-close:hover {
  transition  : .3s;
  opacity     : 1;
  text-shadow : 0 0 15px white;
}

.mfp-figure {
  transform : translateX(-50%);
}

.mfp-fade ~ .blur-layer {
  filter : blur(5px);
}


.cart .mfp-close, .cart-mini .mfp-close {
  position         : absolute;
  top              : -16px;
  left             : 50%;
  transform        : translateX(-50%);
  background-color : #0095D8;
  color            : #fff;
  width            : 39px;
  height           : 30px;
  border-radius    : 8px;
  display          : flex;
  align-items      : flex-end;
  justify-content  : center;
  font-size        : 28px;
  opacity          : 1;
}

.callback-modal-message {
  max-width     : 350px;
  margin        : auto;
  background    : #fff;
  padding       : 20px;
  position      : relative;
  border-radius : 10px;
}

.callback-modal-message__process {
  background      : url('../images/loading.gif') no-repeat center 15px;
  background-size : 50px;
  padding-top     : 80px;
  display         : block;
  text-align      : center;
}

.callback-modal-message__success {
  background      : url('../images/success.png') no-repeat center 15px;
  background-size : 50px;
  padding-top     : 80px;
  display         : block;
  text-align      : center;
}

.callback-modal-message__error {
  background      : url('../images/error.png') no-repeat center 15px;
  background-size : 50px;
  padding-top     : 80px;
  display         : block;
  text-align      : center;
}

.callback-modal-message {
  max-width     : 350px;
  margin        : auto;
  background    : #fff;
  padding       : 20px;
  position      : relative;
  border-radius : 10px
}

.callback-modal__headline {
  text-align  : center;
  font-size   : 20px;
  font-weight : bold
}

.callback-modal__concert-label {
  margin-bottom : 10px;
  display       : block
}


.callback-modal-message {
  max-width     : 350px;
  margin        : auto;
  background    : #fff;
  padding       : 20px;
  position      : relative;
  border-radius : 10px
}

.cartcontent {
  display : none
}

.cartcontent.active {
  display : block
}


.cartcontent {
  padding : 0
}