// блок Clients на главной странице

.clients {
  margin : 100px 0;
  @media (max-width : 425px) {
	position : relative;
	margin   : 50px 0;
  }
}

.clients_title {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  @media (max-width : 768px) {
	line-height : 1;
  }

  .title {
	@media (max-width : 768px) {
	  max-width     : 100%;
	  margin-bottom : 0;
	}
  }
}

.clients_content {
  display     : flex;
  align-items : center;
  @media (max-width : 768px) {
	margin-top : 25px;
  }
}

.clients_content_block {
  border-radius : 18px;
  padding       : 10px 25px;
  text-align    : center;

  &:hover {
	transition       : .3s;
	background-color : $BGcolor;
	border-radius    : 18px;
	box-shadow       : lightgrey 0 0 15px;
  }

  @media (max-width : 768px) {
	background-color : $BGcolor;
  }
}

.clients_pagination {
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  @media (max-width : 425px) {
	width    : 93%;
	z-index  : 5;
	position : absolute;
	bottom   : -50px;
  }
}

.clients_pagination_btn {
  cursor        : pointer;
  padding       : 5px 12px;
  border-radius : 12px;
}
