.com-content-article {
  margin           : 40px 0;
  background-color : #fff;
  border-radius    : 50px;
  padding          : 40px;
  position         : relative;
  @media (max-width : 425px) {
	padding       : 20px;
	border-radius : 15px;
	margin        : 20px 0;
  }

  h1 {
	margin-bottom : 20px;
  }

  h2 {
	font-size : 28px;
	margin    : .65em 0 .35em;
	@media screen and (max-width : 820px) {
	  font-size : 20px;
	}
  }

  h3 {
	font-size : 22px;
	margin    : .65em 0 .25em;
	@media screen and (max-width : 820px) {
	  font-size : 16px;
	}

	& + ul,
	& + ol,
	& + p,
	& + div {
	  margin-top : .5em;
	}
  }

  h4 {
	font-size     : 20px;
	margin-bottom : 6px;
	@media screen and (max-width : 820px) {
	  font-size : 18px;
	}

	& + ul,
	& + ol {
	  margin-top : 0;
	}
  }

  ul {
	margin : 0 0 1rem;

	& + ul {
	  margin-top : -.5rem;
	}

	ul {
	  margin-bottom : 0;
	}
  }

  ol {
	list-style   : decimal;
	padding-left : 24px;
	margin       : 0 0 1rem;

	li {
	  list-style : decimal;
	}
  }

  blockquote {
	&.idea {
	  background  : #F6F6EE;
	  border      : 0;
	  padding     : 1rem 1.25rem;
	  font-family : Georgia, "Times New Roman", Times, serif;
	  box-shadow  : 0 0 25px rgba(0, 0, 0, .15);

	  .title {
		font-weight : 700;
	  }
	}

	&.quote {
	  background  : #2645CE;
	  border      : 0;
	  padding     : 1rem 1.25rem;
	  font-family : Georgia, "Times New Roman", Times, serif;
	  font-size   : 2rem;
	  box-shadow  : 0 0 25px rgba(0, 0, 0, .15);
	  color       : #FFF;
	  @media screen and (max-width : 820px) {
		font-size    : 1.5rem;
		margin-right : -17px;
		margin-left  : -17px;
	  }
	}
  }

  picture {
	margin-bottom : 35px;
	display       : block;

	source {
	  display   : block;
	  max-width : 100%;
	  height    : auto !important;
	}
  }

  p {
	margin-bottom : 1rem;
  }

  a {
	text-decoration          : underline;
	text-decoration-skip-ink : auto;
	color                    : $blue;

	&:hover {
	  text-decoration : none;
	}
  }

  li {
	& + li {
	  margin-top : .5rem;
	}

	ul, ol {
	  margin-top : .5rem;
	}
  }

  ul {
	padding-left : 0;
	list-style   : none;

	li {
	  position     : relative;
	  padding-left : 1.6em;

	  &:before {
		content  : '—';
		position : absolute;
		left     : 0;
		top      : 0;

	  }
	}
  }


  .item-image {
	margin : 0 0 1rem;

	img {
	  display   : block;
	  max-width : 100%;
	  height    : auto;
	}
  }
}

.blog-item {
  margin           : 40px 0;
  background-color : #fff;
  border-radius    : 50px;
  padding          : 40px;
  position         : relative;

  h2 {
	margin-bottom : 6px;
  }

  a {
	color : #0095d8;
  }

  .readmore {
	margin-top : 6px;
  }
}

.com-content-article__body {
  & > *:last-child {
	margin-bottom : 0;
  }

  & > *:first-child {
	margin-top : 0
  }
}