// страница Католог

// визитки табы

.catalog_tabs {
  margin-bottom : 50px;
}

.catalog_tabs_content {

}

.catalog_tabs_items {
  & + .catalog_tabs_btns {
	margin-top : 30px;
  }
}

.catalog_tabs_item {
  padding          : 30px;
  background-color : $BGcolor;
  font-weight      : 600;
  border-radius    : 30px;
  display          : flex;
  gap              : 30px;
  justify-content  : space-between;
  @media (max-width : 800px) {
	flex-direction : column;
  }

  .text {
	h1 {
	  font-family : $titleFonts;
	  font-size   : 52px;
	  margin      : 8px 0 0;
	  @media (max-width : 1024px) {
		font-size : 42px;
	  }
	  @media (max-width : 1024px) {
		font-size : 38px;
	  }
	  @media (max-width : 425px) {
		margin : 8px 0 20px;
	  }
	}

	p {
	  line-height : 22.4px;
	  margin      : 40px 0 20px;
	  font-weight : 400;
	  @media (max-width : 800px) {
		margin : 0;
	  }
	}
  }
}

.catalog_tabs_banner {
  max-width : 500px;
  flex      : 0 0 500px;

  img {
	display : block;
  }

  &.image {
	position : relative;

	img {
	  max-width     : 100%;
	  height        : auto;
	  border-radius : 30px;
	}

	div {
	  position : absolute;
	  left     : 10px;
	  right    : 10px;
	  top      : 30px;
	}

	button {
	  position : absolute;
	  left     : 10px;
	  right    : 10px;
	  bottom   : 15px;
	}
  }


  button {
	margin-top       : 20px;
	color            : $BGcolor;
	padding          : 19px 82px;
	text-transform   : uppercase;
	background-color : $blue;
	border-radius    : 30px;
	@media (max-width : 425px) {
	  padding       : 19px 41px;
	  width         : 100%;
	  border-radius : 15px;
	  box-sizing    : border-box;
	}

	&:hover {
	  opacity : .8;
	}
  }
}

.catalog_tabs_btns {
  display     : flex;
  align-items : center;
  gap         : 20px;
  flex-wrap   : wrap;
  @media (max-width : 768px) {
	display               : grid;
	grid-template-columns : repeat(2, minmax(0, 1fr));
  }
  @media (max-width : 425px) {
	grid-template-columns : repeat(1, minmax(0, 1fr));
  }
}

.catalog_tabs_btn {
  transition       : .3s;
  background-color : $BGcolor;
  border-radius    : 26px;
  padding          : 38px 20px 20px;
  position         : relative;

  &:hover {
	transition : .3s;
	box-shadow : 0 0 30px rgba(0, 0, 0, .12);
	color      : $blue;
  }

  .dot {
	position         : absolute;
	background-color : $blue;
	width            : 6px;
	height           : 6px;
	border-radius    : 3px;
	top              : 19px;
	left             : 50%;
	transform        : translateX(-50%);
  }

  img {
	display : block;
	margin  : -15px auto 5px;
  }
}


.catalog_tabs_btn_title {
  transition  : .3s;
  position    : relative;
  line-height : 24.2px;
  font-size   : 20px;
  font-weight : 500;
  cursor      : pointer;
  display     : block;

  &:hover {
	transition : .3s;
	color      : $blue;
  }
}

// товары

.catalog_card {
  margin : 100px 0;
  @media (max-width : 425px) {
	margin : 50px 0;
  }
}

.catalog_card_content {
  display               : grid;
  gap                   : 20px;
  align-items           : start;
  grid-template-columns : repeat(5, minmax(0, 1fr));
  @media (max-width : 1024px) {
	grid-template-columns : repeat(4, minmax(0, 1fr));
  }
  @media (max-width : 768px) {
	grid-template-columns : repeat(3, minmax(0, 1fr));
  }
  @media (max-width : 600px) {
	grid-template-columns : repeat(2, minmax(0, 1fr));
	gap                   : 25px;
  }
  @media (max-width : 400px) {
	grid-template-columns : repeat(1, minmax(0, 1fr));
	gap                   : 25px;
  }
}

.catalog_card_content_block {
  display         : flex;
  flex-direction  : column;
  justify-content : end;

  .img {
	border-radius : 26px;
	display       : block;
	overflow      : hidden;

	img {
	  max-height    : 220px;
	  max-width     : 325px;
	  object-fit    : cover;
	  width         : auto;
	  height        : auto;
	  border-radius : 26px;
	  z-index       : 2;
	  @media (max-width : 768px) {
		max-width  : 100%;
		max-height : none;
		height     : auto;
		width      : auto;
		display    : block;
	  }
	}
  }

}

.catalog_card_content_info {
  transition       : .3s;
  padding          : 20px;
  background-color : $BGcolor;
  border-radius    : 26px;
  z-index          : 1;

  &:hover {
	box-shadow : 0 0 30px rgba(0, 0, 0, .12);
  }
}

.catalog_card_content_title {
  transition  : .3s;
  font-size   : 20px;
  font-weight : 500;
  height      : 244px;

  &:hover .catalog_card_content_info {
	transition : .3s;
	box-shadow : 0 0 30px rgba(0, 0, 0, .12);
  }

  &:hover {
	transition : .3s;
	color      : $blue;
  }
}

.catalog_card_content_info p {
  line-height : 16.94px;
  font-size   : 14px;
  margin      : 12px 0 20px;
}

.catalog_card_content_price_block {
  display         : flex;
  align-items     : center;
  justify-content : space-between;
}

.catalog_card_content_price {
  font-weight : 600;
  font-size   : 24px;
  color       : $blue;
  line-height : 29.05px;
}

.catalog_card_content_arrow {
  transition       : .3s;
  width            : 34px;
  height           : 34px;
  border-radius    : 17px;
  background-color : #F1F1F1;
  position         : relative;

  &::after {
	transition : .3s;
	content    : "";
	background : url("../images/arrow.svg") center/cover no-repeat;
	width      : 16px;
	height     : 16px;
	position   : absolute;
	left       : 50%;
	top        : 50%;
	transform  : translate(-50%, -50%);
  }

  &:hover {
	background-color : $blue;
	transition       : .3s;
  }
}

.catalog_card_content_arrow:hover::after {
  transition : .3s;
  background : url("../images/arrow-active.svg") center/cover no-repeat;
}
