// хлебные крошки

.breadcrumb {
	margin: 15px 0 20px;
	display: flex;
	align-items: center;
	font-size : 12px;
	font-weight : 300;
}

.breadcrumb-item {
	margin-right: 34px;
	transition: .3s;
	position : relative;
	&::after {
		position : absolute;
		content: "";
		background: url("../images/breadcrumbs-arrow.svg") no-repeat;
		right: -22px;
		top: 50%;
		transform: translateY(-50%);
		width: 10px;
		height : 10px;
	}
}
.breadcrumb-item a {
	transition: .3s;
	&:hover {
		color      : $blue;
		transition : .3s;
	}
}
.breadcrumb-item.active {
	font-weight : 700;
	color: $blue;
	margin: 0;
	&::after {
		content: "";
		background: none;
	}
}
