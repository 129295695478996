// Как мы работаем? ------- Как сделать заказ

.work, .order {
  margin : 100px 0;
  @media (max-width : 428px) {
	margin : 30px 0;
  }

}

.work_inner .title {
  @media (max-width : 768px) {
	max-width : 400px;
  }
}

.order .work_inner .title {
  @media (max-width : 560px) {
	max-width : 300px;
  }
}

.work_box {
  margin  : 50px 0;
  height  : 100%;
  display : flex;
  @media (max-width : 768px) {
	display : block;
  }
}

.work_box_big {
  @media (max-width : 768px) {
	height     : 314px;
	margin-top : 20px;
  }
}

.work_box_grid {
  display               : grid;
  grid-template-columns : repeat(2, 1fr);
  grid-template-rows    : repeat(2, 270px);
  gap                   : 20px;
  margin-right          : 20px;
  @media (max-width : 768px) {
	margin-right          : 0;
	grid-template-columns : repeat(1, 1fr);
	grid-template-rows    : 224px;
  }
}

.work_block {
  display          : grid;
  grid-auto-rows   : 1fr auto 1fr;
  background-color : $BGcolor;
  padding          : 20px;
  border-radius    : 32px;
  position         : relative;
  transition       : .3s;
}

.work_block:hover {
  transition : .3s;
  box-shadow : lightgrey 0 0 15px;
}

.work_block_upper {
  line-height     : 1;
  display         : flex;
  justify-content : space-between;
  align-items     : start;
}

.work_block_icon {
  background-color : #f9f9f9;
  width            : 50px;
  height           : 50px;
  border-radius    : 8px;
  position         : relative;

  &::after {
	content   : "";
	position  : absolute;
	width     : 24px;
	height    : 24px;
	top       : 50%;
	left      : 50%;
	transform : translate(-50%, -50%);
  }
}

// svg icon

.work_i_book::after {
  background : url("../images/book.svg") no-repeat;
}

.work_i_ballot::after {
  background : url("../images/list.svg") no-repeat;
}

.work_i_doc::after {
  background : url("../images/doc.svg") no-repeat;
}

.work_i_wallet::after {
  background : url("../images/wallet.svg") no-repeat;
}

.work_i_calendar::after {
  background : url("../images/calendar.svg") no-repeat;
}

//

.work_block_spray {
  position : absolute;
  right    : 0;
  top      : 2%;
  @media (max-width : 768px) {
	top    : 7%;
	height : 100%;
	width  : 30%;
  }
  @media (max-width : 560px) {
	width : 50%;
  }
}

.work_block_number {
  font-weight : 600;
  font-size   : 80px;
  color       : #f9f9f9;
  @media (max-width : 1024px) {
	font-size : 52px;
  }
}

.work_block a:hover {
  transition : .3s;
  color      : $blue;
}

.work_block_info {
  z-index : 1;
}

.work_block_info_title {
  transition  : .3s;
  font-size   : 24px;
  font-weight : 600;
  @media (max-width : 1024px) {
	font-size : 22px;
  }
  @media (max-width : 768px) {
	font-size : 20px;
  }
}

.work_block_info_text {
  margin-top  : 15px;
  color       : $gray;
  font-weight : 400;
  @media (max-width : 1024px) {
	font-size : 14px;
  }
}




