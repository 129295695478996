// модалка

#checkbox_con {
  display         : flex;
  justify-content : center;
  margin          : 0 20px 15px;
  color           : black;
  font-size       : 13px;

  a {
	color           : $blue;
	text-decoration : underline;
  }
}


input[type="checkbox"] + label::before {
  position         : absolute;
  left             : -12px;
  top              : 3px;
  appearance       : auto;
  content          : '';
  display          : inline-block;
  width            : 5px; /* задаем размер радио кнопки */
  height           : 5px;
  border-radius    : 50%; /* делаем круглую форму радио кнопки */
  background-color : transparent; /* задаем цвет фона для неотмеченного состояния */
  margin-right     : 10px; /* отступ между радио кнопкой и текстом */
}

input[type="checkbox"]:checked + label::before {
  background : $blue;
}

input[type="checkbox"] + label::after {
  position         : absolute;
  left             : -15px;
  top              : 0px;
  content          : '';
  display          : inline-block;
  width            : 9px; /* задаем размер радио кнопки */
  height           : 9px;
  border-radius    : 50%; /* делаем круглую форму радио кнопки */
  border           : 1px solid $blue; /* задаем цвет рамки для неотмеченного состояния */
  background-color : transparent; /* задаем цвет фона для неотмеченного состояния */
  margin-right     : 10px; /* отступ между радио кнопкой и текстом */
}

input[type="checkbox"]:checked + label::after {
  width  : 9px;
  height : 9px;
}

label[for="aggried"] {
  position : relative;
}

label[for="aggried"] a {
  color           : $blue;
  text-decoration : solid;
}

input[type="checkbox"] {
  visibility : hidden;
}


.modal {
  display : none;
}

.modal.active {
  position         : fixed;
  display          : flex;
  align-items      : center;
  justify-content  : center;
  width            : 100%;
  height           : 100%;
  background-color : rgba(0, 0, 0, 0.8);
  z-index          : 99999;
}

.modal_box {
  position         : relative;
  display          : flex;
  align-items      : center;
  justify-content  : center;
  width            : 400px;
  height           : 426px;
  background-color : #f4f4f4;
  border-radius    : 32px;
  @media (max-width : 425px) {
	width  : 290px;
	height : 405px;
  }
}

.modal_block {
  display         : flex;
  align-items     : center;
  justify-content : center;
  width           : 100%;
  height          : 100%;
}

.modal_block_inner {
  width           : 340px;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  @media (max-width : 425px) {
	width   : 100%;
	padding : 20px;
  }
}

.modal_block_upper {
  text-align : center;
}

.modal_block_upper_close {
  top              : -15px;
  transform        : translateX(-50%);
  background-color : $blue;
  border-radius    : 8px;
  padding          : 6px 12px 4px;
  left             : 50%;
  position         : absolute;
  width            : 39px;
  height           : 30px;

  & > span {
	margin-left : 1px;
	position    : relative;
  }

  & span::before {
	position         : absolute;
	content          : "";
	top              : 8px;
	right            : 50%;
	transform        : translateX(50%) rotate(-45deg);
	width            : 16px;
	height           : 2px;
	border-radius    : 2px;
	background-color : $BGcolor;
  }

  & span::after {
	position         : absolute;
	content          : "";
	top              : 8px;
	right            : 50%;
	transform        : translateX(50%) rotate(45deg);
	width            : 16px;
	height           : 2px;
	border-radius    : 2px;
	background-color : $BGcolor;
  }
}

.modal_block_upper_title {
  font-weight : 600;
  font-size   : 24px;
}

.modal_block_upper_text {
  margin : 0 auto;
  width  : 260px;
}

.modal_block_form {
  width : 100%;
}

.modal_block_form_info {
  margin         : 30px 0;
  display        : flex;
  flex-direction : column;
  gap            : 20px;
  @media (max-width : 320px) {
	min-height : 120px;
	height     : 120px;
  }
}

.modal_block_form_info_input {
  border-radius    : 32px;
  background-color : $BGcolor;
  border           : 1px #D4D4D4 solid;
  height           : 54px;
  width            : 100%;
  padding-left     : 20px;
  cursor           : text;
  color            : black;
  transition       : .3s;
}

.modal_block_form_info_input:focus + .modal_block_form_info_label, .modal_block_form_info_input:not(:placeholder-shown) + .modal_block_form_info_label {
  top         : 10px;
  font-size   : 8px;
  font-weight : 300;
  transition  : .3s;
}


.modal_block_form_info_input:invalid:placeholder-shown {
  background-color : $BGcolor;
  border           : 1px #D4D4D4 solid;
}

.modal_block_form_info_input:focus:invalid:placeholder-shown {
  border        : transparent;
  border-bottom : 1px solid $blue;
}

.modal_block_form_info_input:focus {
  border        : transparent;
  border-bottom : 1px solid $blue;
}

.modal_block_form_info_input:invalid {
  border        : transparent;
  border-bottom : 1px solid red;
}

.modal_block_form_info_label {
  transition          : .3s;
  color               : $gray;
  font-size           : 14px;
  font-weight         : 400;
  display             : block;
  position            : absolute;
  left                : 20px;
  -webkit-user-select : none;
  user-select         : none;
  pointer-events      : none;
}

.modal_block_form_con {
  position : relative;
}

.modal_block_form_con label {
  top       : 50%;
  transform : translateY(-50%);
}

.modal_block_form_con label:focus.modal_block_form_info_label, .modal_block_form label[for="number"].modal_block_form_info_input:not(:placeholder-shown) + .modal_block_form label[for="number"].modal_block_form_info_label {
  top : 10px;
}

.modal_block_form input[type="submit"] {
  border-radius    : 100px;
  background-color : $blue;
  width            : 100%;
  height           : 60px;
  font-weight      : 600;
  color            : $BGcolor;
}

.modal_block_form_con input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance : none;
  -moz-appearance    : none;
}

.modal.active header {
  height : 0;
}

.mobile_mask {
  display : none;
}

.nav_menu_mobile_block {
  display : none;
}

