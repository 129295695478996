// стилизация для формы корзины
.mfp-content {
  @media (max-width : 768px) {
	padding : 25px;
  }
  @media (max-width : 425px) {
	padding : 35px 10px 10px 10px;
  }
}

.callback-modal.cart {
  background    : #F3F3F3;
  max-width     : 730px;
  margin        : auto;
  position      : relative;
  padding       : 30px;
  border-radius : 32px;
  @media (max-width : 425px) {
	padding : 20px;
  }
}

.callback-modal.cart-mini {
  background    : #F3F3F3;
  max-width     : 440px;
  margin        : auto;
  position      : relative;
  padding       : 30px;
  border-radius : 32px;
  @media (max-width : 425px) {
	padding : 20px;
  }
}

.cartcontent, .botLineMF {
  color : $black;
}

.cartcontent li {
  display               : grid;
  align-items           : center;
  list-style            : none;
  padding               : 0;
  grid-gap              : 10px;
  grid-template-columns : minmax(0, 1fr) 70px 100px;
  @media (max-width : 425px) {
	grid-template-columns : minmax(0, 1fr) 50px 70px;
  }
}

.cart_title {
  text-align : center;
  color      : $black;
}

.cart-mini .cart_title {
  max-width : 266px;
  margin    : 0 auto;
}

.cart_title > .h2 {
  font-weight : 600;
  font-size   : 24px;
}

.cart_title > p {
  font-size   : 16px;
  font-weight : 400;
}

.cart .cart_input {
  display               : grid;
  grid-template-columns : repeat(2, minmax(0, 1fr));
  gap                   : 20px;
  margin                : 20px 0 30px;
  @media (max-width : 425px) {
	grid-template-columns : repeat(1, minmax(0, 1fr));
  }
}

.cart-mini .cart_input {
  grid-template-columns : repeat(1, minmax(0, 1fr));
  margin                : 30px 0;
}


.cart .modal_block_form_input {
  margin : 0;
}

.cart_item_title {
  font-size   : 12px;
  color       : #242223;
  opacity     : .6;
  font-weight : 300;
  margin      : 20px 0 12px;
}

.cart_item_number {
  display     : flex;
  align-items : center;
}

.cart_item_number > input {
  max-width : 30px
}

.cart-item-delete {
  color      : #D80034;
  opacity    : .8;
  transition : .3s;

  &:hover {
	transition  : .3s;
	opacity     : 1;
	text-shadow : 0 0 15px darkred;
  }
}

.cart_form_text {
  resize           : none;
  width            : 100%;
  height           : 140px;
  cursor           : text;
  border           : 1px #D4D4D4 solid;
  padding          : 20px 0 0 20px;
  border-radius    : 26px;
  background-color : $BGcolor;
}

.cart_form_text:focus {
  border        : transparent;
  border-bottom : 1px $blue solid;
}

.cart-item-content {
  font-size   : 16px;
  font-weight : 400;
  color       : $black;
}

.cart_block_submit {
  margin-top : 40px;
  text-align : center;
}

.cart_button {
  display         : flex;
  justify-content : center;
  gap             : 20px;
  margin          : 0 25px 0 25px;
  @media (max-width : 425px) {
	flex-direction : column;
  }
}

.cart_button_btn {
  width         : 100%;
  height        : 60px;
  font-weight   : 600;
  font-size     : 16px;
  border-radius : 30px;
  transition    : .3s;
  padding       : 0;
}

.cart_button > input {
  background-color : $blue;
  color            : $BGcolor;
  width            : 100%;
  height           : 60px;
  font-weight      : 600;
  font-size        : 16px;
  border-radius    : 30px;
  transition       : .3s;
  padding          : 0;

  &:hover {
	transition  : .3s;
	text-shadow : 0 0 15px $BGcolor;
  }
}

.cart_button > button {
  border : 1px solid $blue;
  color  : $blue;

  &:hover {
	transition : .3s;
	box-shadow : 0 0 15px $blue;
  }
}


.cart_checkbox {
  margin-top  : 12px;
  font-size   : 10px;
  font-weight : 400;
  transition  : .3s;
}

.cart-mini .cart_checkbox {
  max-width       : 212px;
  margin          : 12px auto 0;
  display         : flex;
  align-content   : center;
  justify-content : center;
}

.cart_checkbox a:hover {
  transition      : .3s;
  text-decoration : underline;
}


// input file

.input-file {
  position : relative;
}

.input-file-text {
  display          : flex;
  align-items      : center;
  padding-left     : 20px;
  text-align       : left;
  height           : 54px;
  float            : left;
  width            : 100%;
  border-radius    : 30px;
  color            : rgba(0, 0, 0, .5);
  background-color : #fff;
  border           : 1px solid #d4d4d4;
}

.cart-mini .input-file-text {
  @media (max-width : 375px) {
	visibility : visible;
  }
}

.cart-mini .input-file-btn {
  @media (max-width : 600px) {
	width : 60%;
  }
}

.input-file-btn {
  position         : absolute;
  right            : 0;
  display          : flex;
  justify-content  : center;
  align-items      : center;
  width            : 155px;
  cursor           : pointer;
  color            : #fff;
  border-radius    : 30px;
  background-color : #0095d8;
  height           : 54px;
  transition       : background-color .2s;
  @media (max-width : 600px) {
	width : 60%;
  }
}

.input-file input[type=file] {
  position : absolute;
  z-index  : -1;
  opacity  : 0;
  display  : block;
  width    : 0;
  height   : 0;
}

/* Focus */
.input-file input[type=file]:focus + .input-file-btn {
  box-shadow : 0 0 15px $BGcolor;
}

/* Hover/active */
.input-file:hover .input-file-btn {
  box-shadow : 0 0 15px $BGcolor;
}

.input-file:active .input-file-btn {
  opacity : .8;
}

/* Disabled */
.input-file input[type=file]:disabled + .input-file-btn {
  background-color : #eee;
}


.input-file-btn.active {
  background-color : #D80034;
  color            : $BGcolor;
}
