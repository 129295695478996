.error {
  #outline {
	width      : auto;
	max-width  : 600px;
	background : transparent;
	font-size  : 15px;

	.logo {
	  margin-bottom : 20px;
	}

	#errorboxheader {
	  background  : #0095d8;
	  line-height : 145%;
	  font-size   : 1.5em;
	  border      : 0;
	  width       : auto;
	}

	#errorboxoutline {
	  border : 0;
	  width  : auto;


	  p, ul {
		margin-bottom : 1em;
	  }

	  .btn {
		padding          : 16px 39px;
		background-color : #0095d8;
		border-radius    : 27px;
		font-size        : 16px;
		color            : #FFF;
		display          : inline-block;
	  }
	}
  }

  #techinfo {
	display : none;
  }
}