.page101 {
  .com-content-article {
	padding       : 0;
	border-radius : 0;
	background    : transparent;
  }

  h1 {
	padding       : 30px;
	background    : #FFF;
	border-radius : 50px;
	color         : #0095d8;
  }

  .o-kompanii-intro {
	padding : 0 30px;
	@media (max-width : 420px) {
	  padding : 0 15px;
	}
  }
}

.aboutus_stat {
  display       : flex;
  gap           : 30px;
  flex-wrap     : wrap;
  align-items   : stretch;
  align-content : space-between;

  .item {
	padding       : 30px;
	background    : #FFF;
	border-radius : 50px;
	width         : calc(33% - 30px);

	&:nth-child(n+4) {
	  width : calc(50% - 30px);
	  @media (max-width : 420px) {
		width         : 100%;
		margin-bottom : 0;
	  }
	}

	@media (max-width : 800px) {
	  width         : calc(50% - 30px);
	  margin-bottom : 0;
	}
	@media (max-width : 420px) {
	  width : 100%;
	}

	strong {
	  display        : block;
	  color          : #0095d8;
	  font-size      : 56px;
	  line-height    : 67px;
	  text-align     : center;
	  font-family    : $titleFonts;
	  letter-spacing : -1px;
	}

	p {
	  text-align  : center;
	  color       : #7B7B7B;
	  line-height : 22px;
	  font-size   : 16px;
	}
  }

  &.items4 {
	.item {
	  width : calc(25% - 30px);

	  &:nth-child(n+4) {
		width : calc(25% - 30px);
		@media (max-width : 420px) {
		  width         : 100%;
		  margin-bottom : 0;
		}
	  }

	  @media (max-width : 800px) {
		width : calc(50% - 30px);
	  }
	  @media (max-width : 420px) {
		width : 100%;
	  }

	}
  }
}