
.contact {
  margin : 100px 0 40px;
  @media (max-width : 425px) {
	margin : 50px 0;
  }

  .contact_title {

  }

  .contact_block {
	margin      : 50px 0 20px;
	display     : flex;
	align-items : stretch;
	height      : 455px;
	@media (max-width : 768px) {
	  display : block;
	  height  : auto;
	}
	@media (max-width : 425px) {
	  margin : 20px 0;
	}
  }

  .contact_block_info {
	height           : 100%;
	padding          : 40px;
	background-color : $BGcolor;
	border-radius    : 50px;
	position         : relative;
	@media (max-width : 768px) {
	  margin-bottom : 20px;
	  height        : auto;
	}

	img {
	  position      : absolute;
	  right         : 0;
	  top           : 0;
	  height        : 100%;
	  border-radius : 0 0 50px
	}
  }

  .contact_block_info_box {
	position : relative;
	z-index  : 1;
	width    : 340px;

	@media (max-width : 425px) {
	  width : auto;
	}

  }


  .contact_block_info_title {
	position    : relative;
	z-index     : 1;
	font-weight : 700;
	font-size   : 24px;
  }

  .contact_location {
	font-weight : 500;
	font-size   : 14px;
	max-width   : 305px;

	&:hover {
	  color           : $blue;
	  text-decoration : underline;
	  transition      : .3s;
	}

	@media (max-width : 360px) {
	  max-width : 270px;
	}
  }

  .contact_mail {
	font-weight : 500;
	font-size   : 14px;

	&:hover {
	  color           : $blue;
	  text-decoration : underline;
	  transition      : .3s;
	}
  }

  .contact_block_info_items {
	margin-top : 80px;
	@media (max-width : 768px) {
	  margin-top : 30px;
	}
	@media (max-width : 320px) {
	  width : 220px;
	}
  }

  .contact_title {
	.title {
	  @media (max-width : 768px) {
		max-width : 350px;
	  }
	  @media (max-width : 375px) {
		max-width     : 100%;
		margin-bottom : 0;
	  }
	}
  }

  .contact_block_info_item {
	font-weight   : 400;
	color         : #0b0f14;
	text-align    : left;
	margin-bottom : 20px;

	&:last-child {
	  margin : 0;
	}

	& strong {
	  font-size   : 24px;
	  font-weight : 500;
	}
  }

  .contact_block_map {
	transition    : .3s;
	border-radius : 40px;
	width         : 100%;
	box-shadow    : lightgrey 0 0 15px;

	iframe {
	  border-radius : 40px;
	  @media (max-width : 375px) {
		height : 166px;
	  }
	}
  }

  .contact_block_social {
	width           : 115px;
	display         : flex;
	align-content   : center;
	justify-content : space-between;
  }

  .contact_block_social_bg {
	position        : relative;
	width           : 44px;
	height          : 44px;
	border-radius   : 6px;
	display         : flex;
	align-items     : center;
	justify-content : center;

	&.tg {
	  transition       : .3s;
	  background-color : $blue;

	  &:hover {
		transition : .3s;
		box-shadow : $blue 0 0 15px;
	  }
	}

	&.wa {
	  transition       : .3s;
	  background-color : $lightgray;

	  &:hover {
		transition : .3s;
		box-shadow : lightgreen 0 0 15px
	  }
	}
  }

  .contact_mail {
	font-weight : 500;
	font-size   : 14px;

	&:hover {
	  color           : $blue;
	  text-decoration : underline;
	  transition      : .3s;
	}
  }
}

.question {
  position : relative;
  margin   : 140px auto;
  @media (max-width : 425px) {

	margin : 50px 0;
  }

  .question_inner {
	position        : relative;
	display         : flex;
	align-items     : center;
	justify-content : center;
	width           : 100%;

	& > img {
	  position : absolute;
	  right    : 0;
	  top      : 0;
	  height   : 99%;
	  @media (max-width : 768px) {
		border-radius : 0 0 55px 0;
	  }
	}

	@media (max-width : 425px) {
	  & .mobile_mask {
		border-radius : 0 0 56px 0;
	  }
	}
  }

  .question_image {
	position : relative;

	&::after {
	  border-radius : 56px;
	  display       : block;
	  width         : 100%;
	  height        : 100%;
	  position      : absolute;
	  top           : 0;
	  left          : 0;
	  content       : "";
	  background    : rgb(244, 244, 244);
	  background    : linear-gradient(180deg, rgba(244, 244, 244, 1) 0%, rgba(255, 255, 255, 0) 150%);
	}

	& img {
	  width         : 100%;
	  border-radius : 60px;
	  object-fit    : cover;
	  @media (max-width : 425px) {
		height : 435px;
	  }
	}
  }

  .question_block {
	position   : absolute;
	max-width  : 940px;
	margin     : 0 auto;
	text-align : center;

	.title {
	  @media (max-width : 1024px) {
		font-size : 56px;
	  }
	  @media (max-width : 768px) {
		max-width : 500px;
	  }
	  @media (max-width : 425px) {
		line-height : 1.2;
		font-size   : 42px;
		margin      : 0 auto;
	  }
	  @media (max-width : 375px) {
		font-size : 42px;
	  }
	}

	.question_block_text {
	  margin    : 0 auto;
	  max-width : 470px;
	  font-size : 18px;
	  padding   : 30px 0 60px;
	  @media (max-width : 425px) {
		padding   : 15px 0 30px;
		max-width : 270px;
		font-size : 14px;
	  }
	  @media (max-width : 320px) {
		max-width : 215px;
	  }
	}

	.question_block_send {
	  background-color : $blue;
	  color            : $BGcolor;
	  width            : 65%;
	  margin           : 0 auto;
	  padding          : 19px 0;
	  border-radius    : 30px;
	  transition       : .3s;
	  text-transform   : uppercase;
	  font-weight      : 600;

	  &:hover {
		text-decoration : underline;
		transition      : .3s;
	  }

	  @media (max-width : 320px) {
		width : 80%;
	  }
	}
  }
}

#mod-custom152 {
  background    : #fff;
  border-radius : 50px;
  display       : flex;
  gap           : 20px;
  padding       : 10px;
  @media (max-width : 600px) {
	flex-wrap      : wrap;
	flex-direction : column;
  }

  .text {
	padding : 18px 0 18px 20px;
	width   : calc(100% - 470px);
	@media (max-width : 1000px) {
	  width   : 100%;
	  padding : 20px 20px 0;
	}
  }

  .img {
	width : 440px;
	@media (max-width : 1000px) {
	  width : 100%;
	}

	img {
	  border-radius : 50px;
	  display       : block;
	  width         : 100%;
	  height        : auto;
	}
  }

  h1 {
	margin-bottom : 10px;
  }

  p {
	margin-bottom : 10px;

	& + p {
	  margin-bottom : 0;
	}
  }

  a {
	display          : inline-block;
	background-color : #0095d8;
	color            : #fff;
	text-transform   : uppercase;
	border-radius    : 30px;
	padding          : 19px 30px;
	text-decoration  : none;

	&:hover {
	  opacity : 0.8;
	}
  }
}
