.page297 {
  h1 {
	margin : 30px 0 20px;
  }
}

[itemtype="https://schema.org/FAQPage"] {
  [itemprop="mainEntity"] {
	background    : #FFF;
	padding       : 30px 20px;
	border-radius : 20px;

	& + [itemprop="mainEntity"] {
	  margin-top : 20px;
	}

	[itemprop="name"] {
	  margin-bottom : 10px;
	}
  }
}