// блок в каталоге "Сервис: от идеи до доставки"


.ideas {
	margin: 100px 0;
	position : relative;
}
.ideas_con {
	background: $BGcolor;
	padding: 40px;
	border-radius: 50px;
	@media (max-width: 425px) {
		padding: 30px 20px 20px 20px;
	}
}
.ideas > img {
	z-index : 1;
	position : absolute;
	left: 0;
	height : 100%;
	width: auto;
	@media (max-width: 1024px) {
		width: 100%;
	}
}
.ideas_title {
	z-index : 2;
	position : relative;
	@media (max-width: 768px) {
		max-width: 500px;
	}

}
.ideas_title .title {
	width: auto;
}

.ideas_content {
	z-index : 2;
	position : relative;
	display: grid;
	grid-template-columns: repeat(3, minmax(0,1fr));
	gap: 20px;
	@media (max-width: 768px) {
		grid-template-columns: repeat(1, minmax(0,1fr));
	}
}
.ideas_img {
	max-width: 440px;
	max-height: 220px;
	width: 100%;
	height : 100%;
	object-fit: cover;
	z-index : 3;
	border-radius: 32px;
	@media (max-width: 768px) {
		max-width: 100%;
	}
}
.ideas_block {
	display: flex;
	flex-direction: column;
}
.ideas_info {
	padding: 25px 20px;
	background-color: #F9F9F9;
	border-radius: 32px;
	z-index : 2;
	transition: .3s;
	color: $black;
	&:hover {
		background-color: $BGcolor;
		transition: .3s;
		box-shadow: 0 0 30px rgba(0,0,0, .12);
	}
}
.ideas_info_title {
	font-size : 24px;
	font-weight : 600;
	transition: .3s;
	&:hover {
		transition: .3s;
		color: $blue;
	}
}
.ideas_info_desc {
	color: $gray;
	font-size : 16px;
	font-weight : 400;
	line-height : 22.4px;
	margin: 15px 0 25px;
}
.ideas_info_link {
	text-decoration : underline;
	transition: .3s;
	font-weight : 500;
	&:hover {
		transition: .3s;
		color: $blue;
	}
}
