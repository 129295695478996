// Модуль услуги

.services {
  margin           : 100px 0;
  background-color : $BGcolor;
  border-radius    : 50px;
  width            : 100%;
  position         : relative;
  @media (max-width : 425px) {
	margin : 30px 0;
  }
}

.services > img {
  position : absolute;
  left     : 0;
  top      : 0;
  height   : 100%;
  @media (max-width : 700px) {
	width : 100%;
  }
  @media (max-width : 425px) {
	display : none;
  }
}

.services_title {
  @media (max-width : 1024px) {
	max-width : 600px;
  }
}

.services_inner {
  position : relative;
  padding  : 40px;
  @media (max-width : 768px) {
	padding : 100px 25px 80px;
  }
  @media (max-width : 425px) {
	padding : 30px 15px 30px;
  }
}

.services_block {
  margin-top : 50px;
  @media (max-width : 768px) {
	font-size : 14px;
  }
}


.services_block_info {
  z-index : 1;
  display : flex;
  @media (max-width : 768px) {
	display : block;
  }
}

.services_block_info_image {
  @media (max-width : 768px) {
	display         : flex;
	justify-content : center;
  }
}

.services_block_info_container {
  background-color : #f9f9f9;
  border-radius    : 60px;
  width            : 100%;
  padding          : 40px;
  @media (max-width : 425px) {
	border-radius : 40px;
	padding       : 20px;
  }
}

.services_block_info_container_text {
  max-width : 800px;
}

.services_block_info_container_text li {
  margin-bottom : 25px;
}

.services_block_info_container_text li:last-child {
  margin : 0;
}

.services_block_info_image img {
  min-width     : 345px;
  height        : 322px;
  border-radius : 32px;
  @media (max-width : 425px) {
	min-width : 100%;
	height    : 100%;
	max-width : 100%;
  }

  @media (max-width : 320px) {
	min-width : 150px;
	height    : 270px;
	max-width : 100%;
  }
}

.services_block_items {
  margin                : 20px 0;
  display               : grid;
  grid-template-rows    : repeat(3, 110px);
  grid-template-columns : repeat(4, 1fr);
  gap                   : 20px;
  @media (max-width : 1024px) {
	grid-template-rows    : repeat(3, 110px);
	grid-template-columns : repeat(3, 1fr);
  }
  @media (max-width : 768px) {
	grid-template-rows    : repeat(4, 110px);
	grid-template-columns : repeat(2, 1fr);
  }
  @media (max-width : 425px) {
	grid-template-columns : repeat(1, 1fr);
	grid-template-rows    : auto;
  }
}

.services_block_bg {
  display          : flex;
  align-items      : center;
  position         : relative;
  background-color : #f9f9f9;
  border-radius    : 24px;
  padding-left     : 46px;

  @media (max-width : 425px) {
	padding : 20px 0 20px 46px;
  }
}

.services_block_bg:nth-child(3n) {
  background-color : transparent;
  visibility       : hidden;
  @media (max-width : 1024px) {
	display : none;
  }
}

.services_block_item {
  max-width : 242px;
  @media (max-width : 320px) {
	max-width : 165px;
  }
}

.services_block_item::before {
  content          : "";
  height           : 6px;
  width            : 6px;
  background-color : #0095d8;
  border-radius    : 50%;
  position         : absolute;
  left             : 30px;
  top              : 48%;
}



